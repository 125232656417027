/**
 * AUI Module
 */
.aui-module {
  margin-top: 20px;
}
.aui-module:first-child {
  margin-top: 0;
}
.aui-module-header,
.aui-module-content,
.aui-module-footer {
  box-sizing: border-box;
}
/*! AUI Label */
.aui-label {
  background: #F4F5F7;
  border: 1px solid #DFE1E6;
  border-radius: 3.01px;
  color: #0052CC;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  padding: 1px 5px;
  margin: 0 5px 0 0;
  text-align: left;
  text-decoration: none;
}
a.aui-label {
  color: #0052CC;
}
span.aui-label {
  color: #172B4D;
}
/* Need the split hover to trigger border on parent while split label/X are hovered */
.aui-label.aui-label-closeable.aui-label-split:hover,
a.aui-label:focus,
a.aui-label:hover,
a.aui-label:active {
  border-color: #7A869A;
  text-decoration: none;
}
.aui-label-split .aui-label-split-main:hover,
.aui-label-split .aui-label-split-main:active,
.aui-label-split .aui-label-split-main:focus {
  text-decoration: none;
}
.aui-label.aui-label-closeable {
  padding-right: 18px;
  position: relative;
}
.aui-label-closeable .aui-icon-close {
  /* Small X icon */
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAQAAABuBnYAAAAAKUlEQVQI12NgYFj0nwEKwKxF/9P+Q4TgLAgDIQEVQuJiCKBpwWoosrUAzbg31XT4p6QAAAAASUVORK5CYII=') 0 0 no-repeat;
  cursor: pointer;
  display: block;
  float: right;
  height: 8px;
  position: absolute;
  right: 5px;
  top: 4px;
  width: 8px;
}
/*! AUI Sortable Tables */
.aui-table-sortable .tablesorter-header {
  cursor: pointer;
  border-bottom: 2px solid #DFE1E6;
}
.aui-table-sortable .tablesorter-header.aui-table-column-unsortable {
  cursor: default;
}
.aui-table-sortable .tablesorter-header:focus,
.aui-table-sortable .tablesorter-header:hover {
  background-color: rgba(9, 30, 66, 0.08);
}
.aui-table-sortable .tablesorter-header:active {
  background-color: #DEEBFF;
  border-color: #0747A6;
  color: #0747A6;
}
.aui-table-sortable .tablesorter-headerAsc,
.aui-table-sortable .tablesorter-headerDesc {
  background-color: transparent;
  color: #172B4D;
}
table.aui.aui-table-sortable .tablesorter-header:active {
  border-bottom: 2px solid #0747A6;
}
table.aui.aui-table-sortable .tablesorter-headerAsc,
table.aui.aui-table-sortable .tablesorter-headerDesc {
  border-bottom: 2px solid #7A869A;
}
table.aui.aui-table-sortable .tablesorter-header.aui-table-column-unsortable:focus,
table.aui.aui-table-sortable .tablesorter-header.aui-table-column-unsortable:hover,
table.aui.aui-table-sortable .tablesorter-header.aui-table-column-unsortable:active {
  background-color: inherit;
  border-color: inherit;
  color: #7A869A;
}
.aui-table-sortable .aui-table-header-content::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0;
  font-family: "Adgs Icons";
  font-weight: normal;
  font-style: normal;
  speak: none;
  content: "";
  display: inline-block;
  height: 16px;
  line-height: 1;
  font-size: 16px;
  margin-left: 5px;
  position: relative;
  width: 16px;
  vertical-align: text-bottom;
}
.aui-table-sortable .tablesorter-header:focus .aui-table-header-content::after,
.aui-table-sortable .tablesorter-header:hover .aui-table-header-content::after {
  content: "\f140";
  opacity: 0.5;
}
.aui-table-sortable .tablesorter-headerAsc .aui-table-header-content::after,
.aui-table-sortable .tablesorter-headerAsc:focus .aui-table-header-content::after,
.aui-table-sortable .tablesorter-headerAsc:hover .aui-table-header-content::after {
  content: "\f140";
  opacity: 1;
}
.aui-table-sortable .tablesorter-headerDesc .aui-table-header-content::after,
.aui-table-sortable .tablesorter-headerDesc:focus .aui-table-header-content::after,
.aui-table-sortable .tablesorter-headerDesc:hover .aui-table-header-content::after {
  content: "\f1ac";
  opacity: 1;
}
/* Layout */
.aui-progress-tracker {
  display: table;
  font-size: 12px;
  margin: 10px 0 0;
  padding: 0;
  table-layout: fixed;
  word-wrap: break-word;
  /* doesn't work in IE */
}
.aui-progress-tracker:first-child {
  margin-top: 0;
}
.aui-progress-tracker-step {
  box-sizing: border-box;
  display: table-cell;
  padding: 0 10px;
  max-width: 140px;
  min-width: 80px;
  text-align: center;
}
/* Progress Bar */
.aui-progress-tracker-step > span,
.aui-progress-tracker-step > a {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.42857143;
  letter-spacing: -0.003em;
  text-transform: none;
  font-weight: 400;
  color: #172B4D;
  display: block;
  outline: none;
  padding-top: 25px;
  position: relative;
}
.aui-progress-tracker-step > span:before,
.aui-progress-tracker-step > a:before {
  background: #0065FF;
  border-radius: 100%;
  content: "";
  height: 8px;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  top: 4px;
  width: 8px;
}
.aui-progress-tracker-step + .aui-progress-tracker-step > span:after,
.aui-progress-tracker-step + .aui-progress-tracker-step > a:after {
  background: #0065FF;
  box-shadow: -5px 0 0 -2px #0065FF, 5px 0 0 -2px #0065FF;
  content: "";
  height: 8px;
  left: -50%;
  margin-left: -20px;
  /* touches right edge of previous dot */
  margin-right: 1px;
  /* touches left edge of this dot */
  position: absolute;
  right: 50%;
  top: 4px;
}
.aui-progress-tracker-step-current > span,
.aui-progress-tracker-step-current > a {
  font-weight: 600;
}
.aui-progress-tracker-step-current ~ .aui-progress-tracker-step > span,
.aui-progress-tracker-step-current ~ .aui-progress-tracker-step > a {
  color: #5E6C84;
  font-weight: 400;
}
.aui-progress-tracker-step-current ~ .aui-progress-tracker-step > span:hover,
.aui-progress-tracker-step-current ~ .aui-progress-tracker-step > a:hover {
  color: #5E6C84;
}
.aui-progress-tracker-step-current ~ .aui-progress-tracker-step > span:before,
.aui-progress-tracker-step-current ~ .aui-progress-tracker-step > a:before {
  background-color: #A5ADBA;
}
.aui-progress-tracker-step-current ~ .aui-progress-tracker-step > span:after,
.aui-progress-tracker-step-current ~ .aui-progress-tracker-step > a:after {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.aui-progress-tracker-step > a:hover {
  color: #0052CC;
}
.aui-progress-tracker-step > a:active {
  color: #0747A6;
}
/* Interaction wih page layout */
.aui-page-header-actions .aui-progress-tracker {
  float: right;
}
.tipsy {
  font-size: 10px;
  position: absolute;
  padding: 5px;
  word-wrap: break-word;
  z-index: 100000;
}
.tipsy-inner {
  background-color: #000;
  color: #FFF;
  max-width: 200px;
  padding: 5px 8px 4px 8px;
  text-align: center;
}
/* Rounded corners */
.tipsy-inner {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}
/* Uncomment for shadow */
/*.tipsy-inner { box-shadow: 0 0 5px #000000; -webkit-box-shadow: 0 0 5px #000000; -moz-box-shadow: 0 0 5px #000000; }*/
.tipsy-arrow {
  position: absolute;
  width: 0;
  height: 0;
  line-height: 0;
  border: 5px dashed #000;
}
/* Rules to colour arrows */
.tipsy-arrow-n {
  border-bottom-color: #000;
}
.tipsy-arrow-s {
  border-top-color: #000;
}
.tipsy-arrow-e {
  border-left-color: #000;
}
.tipsy-arrow-w {
  border-right-color: #000;
}
.tipsy-n .tipsy-arrow {
  top: 0px;
  left: 50%;
  margin-left: -5px;
  border-bottom-style: solid;
  border-top: none;
  border-left-color: transparent;
  border-right-color: transparent;
}
.tipsy-nw .tipsy-arrow {
  top: 0;
  left: 10px;
  border-bottom-style: solid;
  border-top: none;
  border-left-color: transparent;
  border-right-color: transparent;
}
.tipsy-ne .tipsy-arrow {
  top: 0;
  right: 10px;
  border-bottom-style: solid;
  border-top: none;
  border-left-color: transparent;
  border-right-color: transparent;
}
.tipsy-s .tipsy-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-top-style: solid;
  border-bottom: none;
  border-left-color: transparent;
  border-right-color: transparent;
}
.tipsy-sw .tipsy-arrow {
  bottom: 0;
  left: 10px;
  border-top-style: solid;
  border-bottom: none;
  border-left-color: transparent;
  border-right-color: transparent;
}
.tipsy-se .tipsy-arrow {
  bottom: 0;
  right: 10px;
  border-top-style: solid;
  border-bottom: none;
  border-left-color: transparent;
  border-right-color: transparent;
}
.tipsy-e .tipsy-arrow {
  right: 0;
  top: 50%;
  margin-top: -5px;
  border-left-style: solid;
  border-right: none;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
.tipsy-w .tipsy-arrow {
  left: 0;
  top: 50%;
  margin-top: -5px;
  border-right-style: solid;
  border-left: none;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
.tipsy {
  font-size: 12px;
  line-height: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  padding: 4px;
  text-align: left;
}
.tipsy-inner {
  background: rgba(51, 51, 51, 0.9);
  padding: 5px 10px;
}
.tipsy-arrow {
  border-color: rgba(51, 51, 51, 0.9);
  border-width: 4px;
}
.tipsy-arrow-n {
  border-bottom-color: rgba(51, 51, 51, 0.9);
  margin-left: -4px;
}
.tipsy-arrow-s {
  border-top-color: rgba(51, 51, 51, 0.9);
  margin-left: -4px;
}
.tipsy-arrow-e {
  border-left-color: rgba(51, 51, 51, 0.9);
  margin-top: -4px;
}
.tipsy-arrow-w {
  border-right-color: rgba(51, 51, 51, 0.9);
  margin-top: -4px;
}
.tipsy a,
.tipsy a:visited {
  color: white;
  text-decoration: underline;
}
.tipsy p {
  color: #DFE1E6;
  margin: 0;
}
.aui-tooltip-title {
  color: #FFFFFF;
  font-weight: bold;
  margin: 0;
}
.aui-expander-short-content[aria-hidden="true"],
.aui-expander-content[aria-hidden="true"],
.aui-expander-content[aria-expanded="true"] .aui-expander-ellipsis {
  display: none;
}
.aui-expander-content,
.aui-expander-content[aria-expanded="false"] {
  height: 0;
  /* Collapsed by default */
  overflow: hidden;
  position: relative;
}
.aui-expander-content[aria-expanded="true"] {
  height: auto;
  /* Expanded by default */
}
.aui-expander-trigger {
  cursor: pointer;
}
/* ADG REVEAL TEXT PATTERN */
a.aui-expander-trigger.aui-expander-reveal-text {
  background: #FFFFFF;
  bottom: 0;
  position: absolute;
  right: 0;
  text-decoration: none;
  padding-left: 10px;
}
.aui-expander-trigger.aui-expander-reveal-text:hover > span {
  text-decoration: underline;
}
.aui-expander-trigger.aui-expander-reveal-text:before {
  color: #172B4D;
  content: "\2026\00a0";
  /* ellipsis */
}
.aui-expander-content[aria-expanded="true"] .aui-expander-trigger.aui-expander-reveal-text:before {
  display: none;
  /* Hide the ellipsis when expanded */
}
.aui-expander-content[aria-expanded="true"] .aui-expander-trigger.aui-expander-reveal-text {
  position: relative;
}
aui-progressbar {
  display: block;
}
.aui-progress-indicator {
  background: rgba(9, 30, 66, 0.13);
  border-radius: 3px;
  height: 6px;
  overflow: hidden;
  position: relative;
  /* so that animations work */
  width: 100%;
}
.aui-progress-indicator-value {
  -webkit-animation: progressSlide 5s infinite ease-in-out;
  animation: progressSlide 5s infinite ease-in-out;
  border-radius: 3px;
  display: block;
  height: 6px;
  transition: none;
  position: absolute;
  width: 10%;
  background: #42526E;
}
@-webkit-keyframes progressSlide {
  0% {
    left: 0%;
  }
  50% {
    left: 90%;
  }
  100% {
    left: 0%;
  }
}
@keyframes progressSlide {
  0% {
    left: 0%;
  }
  50% {
    left: 90%;
  }
  100% {
    left: 0%;
  }
}
.aui-progress-indicator[data-value] .aui-progress-indicator-value {
  -webkit-animation: none;
  animation: none;
  left: 0;
  background: #42526E;
  border-radius: 3px;
  transition: width 0.5s;
}
.aui-progress-indicator-static[data-value] .aui-progress-indicator-value {
  -webkit-animation: none;
  animation: none;
  left: 0;
  transition: none;
}
/* ----- table loading styles ----- */
span.aui-restfultable-throbber {
  background: url('images/wait.gif') center center no-repeat;
  display: inline-block;
  height: 16px;
  text-align: left;
  text-indent: -999px;
  visibility: hidden;
  width: 20px;
}
.aui-restfultable-init {
  text-align: center;
  vertical-align: middle;
  padding: 20px;
}
.aui-restfultable-init .aui-restfultable-loading {
  top: -4px;
  position: relative;
}
.aui-restfultable-init .aui-restfultable-throbber,
.aui-restfultable .aui-restfultable-row.loading .aui-restfultable-throbber {
  visibility: visible;
}
/* ----- Some basics ----- */
table.aui.aui-restfultable > thead > tr > th {
  background-color: #FFFFFF;
}
.aui-restfultable .aui-restfultable-status {
  width: 1px;
  white-space: nowrap;
}
/* ----- Generic row - All states ----- */
.aui-restfultable-row td {
  vertical-align: top;
}
.aui-restfultable-row .aui-restfultable-order {
  width: 8px;
}
.aui-restfultable-row .aui-restfultable-operations {
  width: 160px;
  white-space: nowrap;
}
.aui-restfultable-row .aui-restfultable-throbber {
  width: 20px;
}
.aui-restfultable-row .aui-restfultable-operations input.button,
.aui-restfultable .aui-restfultable-row select {
  margin-top: 2px;
}
.aui-restfultable .aui-restfultable-row input.text,
.aui-restfultable .aui-restfultable-row select {
  box-sizing: border-box;
  margin: 0;
  max-width: none;
  width: 100%;
}
.aui-restfultable .aui-restfultable-order {
  width: 8px;
}
.aui-restfultable .aui-restfultable-row .aui-restfultable-draghandle {
  display: inline-block;
  width: 8px;
  min-height: 24px;
  margin-bottom: -6px;
  background-image: url('images/bg-grippy.png');
  cursor: move;
}
/* ----- Create row ----- */
.aui-restfultable-create td {
  border: solid #DFE1E6;
  border-width: 1px 0;
}
.aui-restfultable .aui-restfultable-create .aui-restfultable-draghandle {
  display: none;
}
/* ----- Focused row ----- */
.aui-restfultable-row.aui-restfultable-focused td {
  background-color: #DEEBFF;
}
/* ----- Disabled row ----- */
.aui-restfultable-row.aui-restfultable-disabled td {
  opacity: 0.5;
}
/* ----- Readonly row ----- */
/* https://ecosystem.atlassian.net/browse/AUI-1940
 * Because the table cells have fractional widths in IE, which then rounds up/down at random when calling clientWidth
 * which means the helper is too narrow to contain the children.
 */
.aui-restfultable-readonly.ui-sortable-helper {
  white-space: nowrap;
}
.aui-restfultable-readonly.aui-restfultable-movable > div {
  background-color: #F4F5F7 !important;
  box-sizing: border-box;
  display: table-cell;
  height: 100%;
  min-height: 40px;
  /* the average height of a row in a restful table. The actual height should be set on the container */
  padding: 7px 10px;
  vertical-align: middle;
}
.aui-restfultable-readonly > .aui-restfultable-movable {
  height: 100%;
}
.aui-restfultable-readonly .aui-restfultable-editable-no-value .aui-restfultable-editable {
  visibility: hidden;
}
.aui-restfultable-readonly .aui-restfultable-operations a {
  visibility: visible;
}
.aui-restfultable-readonly .aui-restfultable-editable {
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 3px 24px 2px 4px;
  position: relative;
}
.aui-restfultable-readonly .aui-restfultable-editable .aui-iconfont-edit,
.aui-restfultable-readonly .aui-restfultable-editable .icon-edit-sml {
  margin: 0;
  position: absolute;
  right: 4px;
  top: 4px;
  visibility: hidden;
}
.aui-restfultable-allowhover td .aui-restfultable-editable .aui-iconfont-edit,
.aui-restfultable-allowhover td .aui-restfultable-editable .icon-edit-sml {
  visibility: hidden;
}
.aui-restfultable-allowhover .aui-restfultable-readonly td:hover .aui-restfultable-editable .aui-iconfont-edit,
.aui-restfultable-allowhover .aui-restfultable-readonly td:hover .aui-restfultable-editable .icon-edit-sml {
  visibility: visible;
}
.aui-restfultable-readonly .aui-restfultable-editable em {
  color: #97A0AF;
}
/* ----- Hover row ----- */
.aui-restfultable-row.aui-restfultable-active {
  background-color: #F4F5F7;
}
.aui-restfultable-allowhover tr:hover td {
  background-color: #F4F5F7;
}
.aui-restfultable-allowhover tr.aui-restfultable-focused td {
  background-color: #DEEBFF;
}
.aui-restfultable-allowhover td:hover .aui-restfultable-editable {
  visibility: visible;
  background: #FFFAE6;
  cursor: pointer;
}
/* ----- Validation ----- */
.aui-restfultable .error {
  clear: both;
  color: #DE350B;
  display: block;
  margin: 5px 0 0 0;
}
/*
Version: 3.4.4 Timestamp: Thu Oct 24 13:23:11 PDT 2013
*/
.select2-container {
  margin: 0;
  position: relative;
  display: inline-block;
  /* inline-block for ie7 */
  zoom: 1;
  *display: inline;
  vertical-align: middle;
}
.select2-container,
.select2-drop,
.select2-search,
.select2-search input {
  /*
    Force border-box so that % widths fit the parent
    container without overlap because of margin/padding.

    More Info : http://www.quirksmode.org/css/box.html
  */
  -webkit-box-sizing: border-box;
  /* webkit */
  -moz-box-sizing: border-box;
  /* firefox */
  box-sizing: border-box;
  /* css3 */
}
.select2-container .select2-choice {
  display: block;
  height: 26px;
  padding: 0 0 0 8px;
  overflow: hidden;
  position: relative;
  border: 1px solid #aaa;
  white-space: nowrap;
  line-height: 26px;
  color: #444;
  text-decoration: none;
  border-radius: 4px;
  background-clip: padding-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #eee), color-stop(0.5, #fff));
  background-image: -webkit-linear-gradient(center bottom, #eee 0%, #fff 50%);
  background-image: -moz-linear-gradient(center bottom, #eee 0%, #fff 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#eeeeee', GradientType=0);
  background-image: linear-gradient(top, #fff 0%, #eee 50%);
}
.select2-container.select2-drop-above .select2-choice {
  border-bottom-color: #aaa;
  border-radius: 0 0 4px 4px;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #eee), color-stop(0.9, #fff));
  background-image: -webkit-linear-gradient(center bottom, #eee 0%, #fff 90%);
  background-image: -moz-linear-gradient(center bottom, #eee 0%, #fff 90%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#eeeeee', GradientType=0);
  background-image: linear-gradient(top, #eee 0%, #fff 90%);
}
.select2-container.select2-allowclear .select2-choice .select2-chosen {
  margin-right: 42px;
}
.select2-container .select2-choice > .select2-chosen {
  margin-right: 26px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.select2-container .select2-choice abbr {
  display: none;
  width: 12px;
  height: 12px;
  position: absolute;
  right: 24px;
  top: 8px;
  font-size: 1px;
  text-decoration: none;
  border: 0;
  background: url('select2.png') right top no-repeat;
  cursor: pointer;
  outline: 0;
}
.select2-container.select2-allowclear .select2-choice abbr {
  display: inline-block;
}
.select2-container .select2-choice abbr:hover {
  background-position: right -11px;
  cursor: pointer;
}
.select2-drop-mask {
  border: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 9998;
  /* styles required for IE to work */
  background-color: #fff;
  filter: alpha(opacity=0);
}
.select2-drop {
  width: 100%;
  margin-top: -1px;
  position: absolute;
  z-index: 9999;
  top: 100%;
  background: #fff;
  color: #000;
  border: 1px solid #aaa;
  border-top: 0;
  border-radius: 0 0 4px 4px;
  -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}
.select2-drop-auto-width {
  border-top: 1px solid #aaa;
  width: auto;
}
.select2-drop-auto-width .select2-search {
  padding-top: 4px;
}
.select2-drop.select2-drop-above {
  margin-top: 1px;
  border-top: 1px solid #aaa;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
  -webkit-box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
}
.select2-drop-active {
  border: 1px solid #5897fb;
  border-top: none;
}
.select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid #5897fb;
}
.select2-container .select2-choice .select2-arrow {
  display: inline-block;
  width: 18px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border-left: 1px solid #aaa;
  border-radius: 0 4px 4px 0;
  background-clip: padding-box;
  background: #ccc;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #ccc), color-stop(0.6, #eee));
  background-image: -webkit-linear-gradient(center bottom, #ccc 0%, #eee 60%);
  background-image: -moz-linear-gradient(center bottom, #ccc 0%, #eee 60%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#cccccc', GradientType=0);
  background-image: linear-gradient(top, #ccc 0%, #eee 60%);
}
.select2-container .select2-choice .select2-arrow b {
  display: block;
  width: 100%;
  height: 100%;
  background: url('select2.png') no-repeat 0 1px;
}
.select2-search {
  display: inline-block;
  width: 100%;
  min-height: 26px;
  margin: 0;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  z-index: 10000;
  white-space: nowrap;
}
.select2-search input {
  width: 100%;
  height: auto !important;
  min-height: 26px;
  padding: 4px 20px 4px 5px;
  margin: 0;
  outline: 0;
  font-family: sans-serif;
  font-size: 1em;
  border: 1px solid #aaa;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #fff url('select2.png') no-repeat 100% -22px;
  background: url('select2.png') no-repeat 100% -22px, -webkit-gradient(linear, left bottom, left top, color-stop(0.85, #fff), color-stop(0.99, #eee));
  background: url('select2.png') no-repeat 100% -22px, -webkit-linear-gradient(center bottom, #fff 85%, #eee 99%);
  background: url('select2.png') no-repeat 100% -22px, -moz-linear-gradient(center bottom, #fff 85%, #eee 99%);
  background: url('select2.png') no-repeat 100% -22px, linear-gradient(top, #fff 85%, #eee 99%);
}
.select2-drop.select2-drop-above .select2-search input {
  margin-top: 4px;
}
.select2-search input.select2-active {
  background: #fff url('select2-spinner.gif') no-repeat 100%;
  background: url('select2-spinner.gif') no-repeat 100%, -webkit-gradient(linear, left bottom, left top, color-stop(0.85, #fff), color-stop(0.99, #eee));
  background: url('select2-spinner.gif') no-repeat 100%, -webkit-linear-gradient(center bottom, #fff 85%, #eee 99%);
  background: url('select2-spinner.gif') no-repeat 100%, -moz-linear-gradient(center bottom, #fff 85%, #eee 99%);
  background: url('select2-spinner.gif') no-repeat 100%, linear-gradient(top, #fff 85%, #eee 99%);
}
.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
  border: 1px solid #5897fb;
  outline: none;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.select2-dropdown-open .select2-choice {
  border-bottom-color: transparent;
  -webkit-box-shadow: 0 1px 0 #fff inset;
  box-shadow: 0 1px 0 #fff inset;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #eee;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #fff), color-stop(0.5, #eee));
  background-image: -webkit-linear-gradient(center bottom, #fff 0%, #eee 50%);
  background-image: -moz-linear-gradient(center bottom, #fff 0%, #eee 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#ffffff', GradientType=0);
  background-image: linear-gradient(top, #fff 0%, #eee 50%);
}
.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
  border: 1px solid #5897fb;
  border-top-color: transparent;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff), color-stop(0.5, #eee));
  background-image: -webkit-linear-gradient(center top, #fff 0%, #eee 50%);
  background-image: -moz-linear-gradient(center top, #fff 0%, #eee 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#ffffff', GradientType=0);
  background-image: linear-gradient(bottom, #fff 0%, #eee 50%);
}
.select2-dropdown-open .select2-choice .select2-arrow {
  background: transparent;
  border-left: none;
  filter: none;
}
.select2-dropdown-open .select2-choice .select2-arrow b {
  background-position: -18px 1px;
}
/* results */
.select2-results {
  max-height: 200px;
  padding: 0 0 0 4px;
  margin: 4px 4px 4px 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.select2-results ul.select2-result-sub {
  margin: 0;
  padding-left: 0;
}
.select2-results ul.select2-result-sub > li .select2-result-label {
  padding-left: 20px;
}
.select2-results ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 40px;
}
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 60px;
}
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 80px;
}
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 100px;
}
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 110px;
}
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 120px;
}
.select2-results li {
  list-style: none;
  display: list-item;
  background-image: none;
}
.select2-results li.select2-result-with-children > .select2-result-label {
  font-weight: bold;
}
.select2-results .select2-result-label {
  padding: 3px 7px 4px;
  margin: 0;
  cursor: pointer;
  min-height: 1em;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.select2-results .select2-highlighted {
  background: #3875d7;
  color: #fff;
}
.select2-results li em {
  background: #feffde;
  font-style: normal;
}
.select2-results .select2-highlighted em {
  background: transparent;
}
.select2-results .select2-highlighted ul {
  background: #fff;
  color: #000;
}
.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit {
  background: #f4f4f4;
  display: list-item;
}
/*
disabled look for disabled choices in the results dropdown
*/
.select2-results .select2-disabled.select2-highlighted {
  color: #666;
  background: #f4f4f4;
  display: list-item;
  cursor: default;
}
.select2-results .select2-disabled {
  background: #f4f4f4;
  display: list-item;
  cursor: default;
}
.select2-results .select2-selected {
  display: none;
}
.select2-more-results.select2-active {
  background: #f4f4f4 url('select2-spinner.gif') no-repeat 100%;
}
.select2-more-results {
  background: #f4f4f4;
  display: list-item;
}
/* disabled styles */
.select2-container.select2-container-disabled .select2-choice {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #ddd;
  cursor: default;
}
.select2-container.select2-container-disabled .select2-choice .select2-arrow {
  background-color: #f4f4f4;
  background-image: none;
  border-left: 0;
}
.select2-container.select2-container-disabled .select2-choice abbr {
  display: none;
}
/* multiselect */
.select2-container-multi .select2-choices {
  height: auto !important;
  height: 1%;
  margin: 0;
  padding: 0;
  position: relative;
  border: 1px solid #aaa;
  cursor: text;
  overflow: hidden;
  background-color: #fff;
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, color-stop(1%, #eee), color-stop(15%, #fff));
  background-image: -webkit-linear-gradient(top, #eee 1%, #fff 15%);
  background-image: -moz-linear-gradient(top, #eee 1%, #fff 15%);
  background-image: linear-gradient(top, #eee 1%, #fff 15%);
}
.select2-locked {
  padding: 3px 5px 3px 5px !important;
}
.select2-container-multi .select2-choices {
  min-height: 26px;
}
.select2-container-multi.select2-container-active .select2-choices {
  border: 1px solid #5897fb;
  outline: none;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.select2-container-multi .select2-choices li {
  float: left;
  list-style: none;
}
.select2-container-multi .select2-choices .select2-search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.select2-container-multi .select2-choices .select2-search-field input {
  padding: 5px;
  margin: 1px 0;
  font-family: sans-serif;
  font-size: 100%;
  color: #666;
  outline: 0;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent !important;
}
.select2-container-multi .select2-choices .select2-search-field input.select2-active {
  background: #fff url('select2-spinner.gif') no-repeat 100% !important;
}
.select2-default {
  color: #999 !important;
}
.select2-container-multi .select2-choices .select2-search-choice {
  padding: 3px 5px 3px 18px;
  margin: 3px 0 3px 5px;
  position: relative;
  line-height: 13px;
  color: #333;
  cursor: default;
  border: 1px solid #aaaaaa;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
  background-clip: padding-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #e4e4e4;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#f4f4f4', GradientType=0);
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), color-stop(100%, #eee));
  background-image: -webkit-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
  background-image: -moz-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
  background-image: linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
}
.select2-container-multi .select2-choices .select2-search-choice .select2-chosen {
  cursor: default;
}
.select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #d4d4d4;
}
.select2-search-choice-close {
  display: block;
  width: 12px;
  height: 13px;
  position: absolute;
  right: 3px;
  top: 4px;
  font-size: 1px;
  outline: none;
  background: url('select2.png') right top no-repeat;
}
.select2-container-multi .select2-search-choice-close {
  left: 3px;
}
.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:hover {
  background-position: right -11px;
}
.select2-container-multi .select2-choices .select2-search-choice-focus .select2-search-choice-close {
  background-position: right -11px;
}
/* disabled styles */
.select2-container-multi.select2-container-disabled .select2-choices {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #ddd;
  cursor: default;
}
.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  padding: 3px 5px 3px 5px;
  border: 1px solid #ddd;
  background-image: none;
  background-color: #f4f4f4;
}
.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close {
  display: none;
  background: none;
}
/* end multiselect */
.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match {
  text-decoration: underline;
}
.select2-offscreen,
.select2-offscreen:focus {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0px !important;
  top: 0px !important;
}
.select2-display-none {
  display: none;
}
.select2-measure-scrollbar {
  position: absolute;
  top: -10000px;
  left: -10000px;
  width: 100px;
  height: 100px;
  overflow: scroll;
}
/* Retina-ize icons */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .select2-search input,
  .select2-search-choice-close,
  .select2-container .select2-choice abbr,
  .select2-container .select2-choice .select2-arrow b {
    background-image: url('select2x2.png') !important;
    background-repeat: no-repeat !important;
    background-size: 60px 40px !important;
  }
  .select2-search input {
    background-position: 100% -21px !important;
  }
}
/*
    ADG styles to be added to select2 items.

    There are 5 main components that select2 uses.
    # select2-container - the top-level container that wraps the original <input> element
    ## select2-choice - the <a> the user clicks on to trigger the dropdown, shows the selected item
    # select2-drop - the top-level container directly in the <body> that displays the dropdown
    ## select2-search - the search box in the dropdown
    ## select2-results - the list of results in the dropdown, including groups

    Overrides in this CSS file should start with:
    # .aui-select2-container - in auiSelect2 JS we add this class to the top container
    # .aui-select2-drop - in auiSelect2 JS we add this class to the dropdown container

    By sticking to these two selector elements we won't accidentally break other code that uses select2. If they want
    sexy ADG styles they can call auiSelect2() instead of select2().
    Please don't add application-specific styles/rules in here, as these styles will eventually be pulled into AUI.
*/
.aui-select2-drop .select2-search input {
  min-height: 0;
  font-family: inherit;
}
.aui-select2-drop .select2-result-selectable .select2-match,
.aui-select2-drop .select2-result-unselectable .select2-match {
  text-decoration: none;
  font-weight: bold;
}
.aui-select2-drop .select2-results ul.select2-result-sub {
  padding: 0;
}
.aui-select2-drop.aui-dropdown2 .select2-search {
  padding: 5px;
}
.aui-select2-drop {
  margin-top: 3px;
  margin-bottom: 0;
  z-index: 9999;
}
.aui-select2-drop.select2-drop-above.select2-drop-active {
  box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31);
  background: #FFFFFF;
  border: 0 solid #DFE1E6;
  border-radius: 3px;
  color: #344563;
  margin-bottom: 3px;
  margin-top: 0;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .aui-select2-drop.select2-drop-above.select2-drop-active {
    box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.13), 1px 0 1px rgba(9, 30, 66, 0.13), -1px 0 1px rgba(9, 30, 66, 0.13), 0 -1px 1px rgba(9, 30, 66, 0.13);
  }
}
.aui-select2-drop .select2-results ul.select2-result-sub > li .select2-result-label,
.aui-select2-drop .select2-results .select2-result-label,
.aui-select2-drop .select2-results .select2-searching,
.aui-select2-drop .select2-results .select2-no-results,
.aui-select2-drop .select2-results .select2-more-results {
  box-sizing: content-box;
  display: block;
  line-height: 1.14285714;
  padding: 7px 10px;
}
.aui-select2-drop .select2-result-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: inherit;
}
.aui-select2-drop .select2-results {
  margin: 0;
  padding: 0;
}
.aui-select2-drop .select2-more-results,
.aui-select2-drop .select2-searching,
.aui-select2-drop .select2-no-results {
  background-color: transparent;
}
.aui-select2-drop .select2-search input,
.aui-select2-drop {
  border: 1px solid transparent;
  /* make border color consistent with AUI button border color */
}
.aui-select2-drop .select2-search input {
  border-radius: 3.01px;
}
.aui-select2-container.select2-container .select2-choice,
.aui-select2-drop {
  color: inherit;
  max-width: none;
}
.aui-select2-drop.aui-dropdown2.aui-style-default {
  margin-left: 0;
}
.aui-select2-drop .select2-results .select2-result-selectable {
  background-color: transparent;
  color: #344563;
  text-decoration: none;
  cursor: pointer;
}
.aui-select2-drop .select2-results .select2-highlighted {
  background-color: #EBECF0;
  color: #344563;
}
.aui-select2-drop .select2-results .select2-result-unselectable {
  background-color: transparent;
  color: #7A869A;
  cursor: not-allowed;
  text-decoration: none;
}
.aui-select2-drop .select2-results .select2-result-with-children {
  border-top: 1px solid #DFE1E6;
  border-bottom: 1px solid #DFE1E6;
  margin: 3px 0;
  padding: 0;
}
.aui-select2-drop .select2-results .select2-result-with-children:first-child {
  border-top: 0;
  margin-top: 0;
}
.aui-select2-drop .select2-results .select2-result-with-children:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}
.aui-select2-drop .select2-results .select2-result-with-children > :first-child {
  margin-top: 3px;
}
.aui-select2-drop .select2-results .select2-result-with-children > :last-child {
  margin-bottom: 3px;
}
.aui-select2-drop .select2-results .select2-result-with-children > .select2-result-label {
  color: #6B778C;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.45454545;
  letter-spacing: 0;
  text-transform: uppercase;
}
.aui-select2-drop .select2-results .select2-result-with-children + .select2-result > .select2-result-label {
  margin-top: 3px;
}
.aui-select2-drop .select2-results .select2-result-with-children + .select2-result-with-children {
  border-top: 0;
}
.aui-select2-container.select2-container .select2-choice > span {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.aui-select2-container.select2-container .select2-choice .select2-arrow {
  background: none;
  border: none;
  filter: none;
}
.aui-select2-container.select2-container-active .select2-choice,
.aui-select2-container.select2-container-active .select2-choices {
  border: none;
  outline: none;
  box-shadow: none;
}
.aui-select2-container.select2-container .select2-choice .select2-arrow b {
  display: none;
  background: none;
}
.aui-select2-container.select2-container .select2-choice {
  padding-right: 23px;
  /* 8px (arrow width) + 10px (right padding) + 5px (margin between arrow and logo) */
  position: relative;
  width: inherit;
}
.aui-select2-container.select2-container a.select2-choice,
.aui-select2-container.select2-container a.select2-choice:focus,
.aui-select2-container.select2-container a.select2-choice:hover,
.aui-select2-container.select2-container a.select2-choice:active {
  text-decoration: none;
}
.aui-select2-container.select2-container .select2-choice:after {
  border: 4px solid transparent;
  content: "";
  height: 0;
  left: 100%;
  /* "left" + "margin-left" required because of webkit not working properly with "right" */
  margin-left: -18px;
  margin-top: -2px;
  opacity: 1;
  position: absolute;
  top: 50%;
  width: 0;
}
.aui-select2-container.select2-container .select2-choice:after {
  border-top-color: #344563;
}
.aui-select2-container.select2-container .select2-choice:hover:after,
.aui-select2-container.select2-container .select2-choice.active:after {
  opacity: 1;
}
.aui-select2-container.select2-container .select2-choice:after {
  margin-top: 0;
  top: 12px;
}
/* Set the default text color to inherit.
   NOTE: this class is only marked as !important because Select2 marks the same
   selector as !important in it's styles. */
.aui-select2-container .select2-input {
  color: inherit !important;
}
/* Since select2 uses the placeholder text as the value of the input,
  the following style is used to override text color to mach ADG placeholder.
  Select2 gives the input a class of select2-default when the value represents the placeholder value.
  NOTE: this class is only marked as !important because Select2 marks the same
  selector as !important in its styles. */
.aui-select2-container .select2-default {
  color: #7A869A !important;
}
.aui-select2-container .select2-choices .select2-search-field input {
  font-family: inherit;
  font-size: 14px;
  height: 1.4285714285714em;
  /* 20px - using ems so the fields increase in line with user specified font-sizes */
  line-height: 1.4285714285714;
  margin: 0;
  padding: 0;
}
.aui-select2-container.select2-container-multi .select2-choices {
  min-height: 0;
  background: #FFFFFF;
}
.aui-select2-container.select2-container-multi.text {
  height: auto;
}
.aui-select2-container .select2-choices .select2-search-choice {
  background: transparent;
  border: 1px solid #DFE1E6;
  border-radius: 3.01px;
  color: #344563;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  padding: 1px 18px 1px 5px;
  position: relative;
  margin: 1px 5px 1px 0;
  text-align: left;
  text-decoration: none;
}
.aui-select2-container .select2-choices .select2-search-choice-focus {
  border-color: transparent;
}
.aui-select2-container .select2-choice abbr {
  padding-top: 10px;
  position: absolute;
  top: 10px;
}
.aui-select2-container.select2-allowclear .select2-choice abbr {
  display: block;
}
.aui-select2-container .select2-search-choice-close {
  cursor: pointer;
  display: block;
  height: 8px;
  left: auto;
  position: absolute;
  right: 5px;
  top: 4px;
  width: 8px;
}
/* Clear select2's overrides for retina displays */
@media all, (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5) {
  .aui-select2-container.select2-container .select2-search-choice-close,
  .aui-select2-container.select2-container .select2-choice abbr {
    /* !important to ensure that the select2 hover state doesn't modify the background position */
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAQAAABuBnYAAAAAKUlEQVQI12NgYFj0nwEKwKxF/9P+Q4TgLAgDIQEVQuJiCKBpwWoosrUAzbg31XT4p6QAAAAASUVORK5CYII=') 0 0 no-repeat !important;
    background-size: 8px !important;
  }
}
/* In order to beat out select2's overrides, the aui-button style had to be copied in below.
   Unfortunately, any changes to that style will have to be copied here as well to keep the select2
   element consistent.*/
.aui-select2-container.select2-container .select2-choice,
.aui-select2-container.select2-container a.select2-choice,
.aui-select2-container.select2-container .select2-choice:visited {
  box-sizing: border-box;
  transition: background-color 0.1s ease-out;
  background: rgba(9, 30, 66, 0.08);
  border: 1px solid transparent;
  border-radius: 3.01px;
  color: #344563;
  cursor: pointer;
  display: inline-block;
  filter: none;
  font-family: inherit;
  font-size: inherit;
  font-variant: normal;
  font-weight: normal;
  height: 2.1428571428571em;
  /* 30px - using ems so the fields increase in line with user specified font-sizes */
  line-height: 1.4285714285714;
  margin: 0;
  padding: 4px 10px;
  text-decoration: none;
  text-shadow: none;
  vertical-align: top;
  white-space: nowrap;
}
.aui-select2-container.select2-container .select2-choice:hover {
  background: #EBECF0;
  border-color: transparent;
  box-shadow: none;
  color: #344563;
  text-decoration: none;
}
.aui-select2-container.select2-container .select2-choice:hover::after {
  border-top-color: #344563;
}
.aui-select2-container.select2-container.select2-container-active .select2-choice,
.aui-select2-container.select2-container .select2-choice:active {
  background: #DEEBFF;
  border-color: transparent;
  color: #0052CC;
  box-shadow: none;
  filter: none;
  text-shadow: none;
  text-decoration: none;
}
.aui-select2-container.select2-container.select2-container-active .select2-choice::after,
.aui-select2-container.select2-container .select2-choice:active::after {
  border-top-color: #0052CC;
}
.aui-select2-container.select2-container.select2-dropdown-open:not(.select2-container-multi) .select2-choice,
.aui-select2-container.select2-container.select2-dropdown-open:not(.select2-container-multi) .select2-choices {
  /* square off bottom corners */
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-width: 0;
  /* avoid double border when the dropdown is active - increase padding to cater for this reduction */
  padding-bottom: 5px;
  /* increase the padding to cater for the lack of border so the button doesn't change size */
  background: #344563;
  color: #FFFFFF;
}
.aui-select2-container.select2-container.select2-dropdown-open:not(.select2-container-multi) .select2-choice::after,
.aui-select2-container.select2-container.select2-dropdown-open:not(.select2-container-multi) .select2-choices::after {
  border-top-color: #FFFFFF;
}
.aui-select2-container.select2-container.select2-dropdown-open.select2-drop-above .select2-choice,
.aui-select2-container.select2-container.select2-dropdown-open.select2-drop-above .select2-choices {
  border-radius: 3.01px;
  /* restore border-radius */
  border-bottom-width: 1px;
  /* restore bottom border */
  /* square off top corners instead */
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-top-width: 0;
  /* avoid double border when the dropdown is active - increase padding to cater for this reduction */
  padding-bottom: 4px;
  /* restore bottom padding */
  padding-top: 5px;
  /* increase the padding to cater for the lack of border so the button doesn't change size */
}
.aui-select2-container.select2-container.select2-drop-above .select2-choice,
.aui-select2-container.select2-dropdown-open.select2-drop-above .select2-choice,
.aui-select2-container.select2-dropdown-open.select2-drop-above .select2-choices,
.aui-select2-container.select2-container-multi .select2-choices .select2-search-choice {
  filter: none;
}
.aui-select2-container.select2-container .select2-choice abbr {
  display: none;
}
.aui-select2-container .aui-avatar {
  margin-right: 5px;
}
.aui-select2-container.aui-has-avatar .select2-search-choice-close {
  top: 5px;
}
.aui-select2-container.aui-has-avatar .select2-choices .select2-search-field input {
  padding: 1px 0;
}
.aui-select2-drop.aui-has-avatar .select2-results .select2-result-label .aui-avatar {
  margin-right: 5px;
  vertical-align: middle;
}
.aui-select2-drop.aui-has-avatar .select2-more-results,
.aui-select2-drop.aui-has-avatar .select2-searching,
.aui-select2-drop.aui-has-avatar .select2-no-results {
  padding-bottom: 5px;
  padding-top: 5px;
}
#aui-flag-container {
  pointer-events: none;
  position: fixed;
  top: 70px;
  right: 30px;
  z-index: 4000;
}
.aui-flag {
  left: 0;
  max-height: 300px;
  opacity: 0;
  position: relative;
  top: -10px;
  transition: opacity 0.2s, top 0.5s;
}
.aui-flag[aria-hidden="true"] {
  left: 300px;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  top: 0;
  transition: max-height 0.5s 0.5s, margin-bottom 0.5s 0.5s, opacity 0.8s, left 1s;
}
.aui-flag[aria-hidden="false"] {
  margin-bottom: 20px;
  opacity: 1;
  top: 0;
  left: 0;
}
.aui-flag .aui-message {
  box-shadow: 0 20px 32px -8px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31);
  pointer-events: auto;
  border-width: 0;
  width: 300px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .aui-flag .aui-message {
    box-shadow: 0 20px 32px -8px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.13), 1px 0 1px rgba(9, 30, 66, 0.13), -1px 0 1px rgba(9, 30, 66, 0.13), 0 -1px 1px rgba(9, 30, 66, 0.13);
  }
}
/*
    Tipsy's
*/
.tipsy.aui-form-notification-tooltip-error .tipsy-arrow-n {
  border-bottom-color: #DE350B;
}
.tipsy.aui-form-notification-tooltip-error .tipsy-arrow-s {
  border-top-color: #DE350B;
}
.tipsy.aui-form-notification-tooltip-error .tipsy-arrow-w {
  border-right-color: #DE350B;
}
.tipsy.aui-form-notification-tooltip-error .tipsy-inner {
  background-color: #DE350B;
  color: #FFFFFF;
}
.tipsy.aui-form-notification-tooltip-info .tipsy-arrow-n {
  border-bottom-color: #FFFFFF;
}
.tipsy.aui-form-notification-tooltip-info .tipsy-arrow-s {
  border-top-color: #FFFFFF;
}
.tipsy.aui-form-notification-tooltip-info .tipsy-arrow-w {
  border-right-color: #FFFFFF;
}
.tipsy.aui-form-notification-tooltip-info .tipsy-inner {
  background-color: #FFFFFF;
  border: 1px solid #DFE1E6;
  color: #172B4D;
}
.tipsy.aui-form-notification-tooltip-info .tipsy-inner a {
  color: #0052CC;
  text-decoration: none;
}
.tipsy.aui-form-notification-tooltip-info:before {
  content: '';
  height: 0;
  position: absolute;
  width: 0;
}
.tipsy.aui-form-notification-tooltip-info.tipsy-ne:before {
  border-bottom: 7px #DFE1E6 solid;
  border-left: 7px transparent dashed;
  border-right: 7px transparent dashed;
  border-top: none;
  margin-top: 0;
  right: 9px;
  top: -1px;
}
.tipsy.aui-form-notification-tooltip-info.tipsy-se:before {
  border-bottom: none;
  border-left: 7px transparent dashed;
  border-right: 7px transparent dashed;
  border-top: 7px #DFE1E6 solid;
  margin-top: -5px;
  right: 9px;
  top: 100%;
}
.tipsy.aui-form-notification-tooltip-info.tipsy-w:before {
  border-bottom: 7px transparent dashed;
  border-left: none;
  border-right: 7px #DFE1E6 solid;
  border-top: 7px transparent dashed;
  left: -3px;
  margin-top: -7px;
  top: 50%;
}
.tipsy.aui-form-notification-tooltip {
  line-height: 1.28571429;
  padding: 4px;
}
.tipsy.aui-form-notification-tooltip .tipsy-arrow-n {
  border-width: 6px;
  margin-top: 0;
  right: 10px;
  top: 0;
}
.tipsy.aui-form-notification-tooltip .tipsy-arrow-s {
  border-width: 6px;
  margin-top: -6px;
  right: 10px;
}
.tipsy.aui-form-notification-tooltip .tipsy-arrow-w {
  border-width: 6px;
  margin-top: -6px;
  left: -1px;
}
.tipsy.aui-form-notification-tooltip ul {
  padding-left: 20px;
}
.tipsy.aui-form-notification-tooltip .tipsy-inner {
  padding: 10px;
  text-align: left;
}
/*
    Icons.
*/
[data-aui-notification-info] + .aui-icon.aui-icon-notification {
  color: #97A0AF;
}
[data-aui-notification-info] + .aui-icon.aui-icon-notification:before {
  content: "\f234";
}
[data-aui-notification-success] + .aui-icon.aui-icon-notification {
  color: #00875A;
}
[data-aui-notification-success] + .aui-icon.aui-icon-notification:before {
  content: "\f104";
}
[data-aui-notification-error] + .aui-icon.aui-icon-notification {
  color: #DE350B;
}
[data-aui-notification-error] + .aui-icon.aui-icon-notification:before {
  content: "\f15a";
}
[data-aui-notification-field].aui-form-notification-field-no-icon + .aui-icon.aui-icon-notification {
  visibility: hidden;
}
.aui-icon-notification {
  margin-left: 2px;
  margin-top: 4px;
  vertical-align: top;
}
form.aui .text[data-aui-notification-field]:not(.aui-form-notification-field-no-icon),
form.aui .password[data-aui-notification-field]:not(.aui-form-notification-field-no-icon) {
  padding-right: 28px;
}
form.aui .text[data-aui-notification-field] + .aui-icon-notification,
form.aui .password[data-aui-notification-field] + .aui-icon-notification {
  margin-left: 0;
  margin-top: 0;
  position: relative;
  right: 22px;
  top: 7px;
  vertical-align: top;
}
form.aui .password[data-aui-notification-error],
form.aui .text[data-aui-notification-error],
form.aui .textarea[data-aui-notification-error] {
  border-color: #DE350B;
}
form.aui .password[data-aui-notification-success],
form.aui .text[data-aui-notification-success],
form.aui .textarea[data-aui-notification-success] {
  border-color: #00875A;
}
.aui-page-sidebar #footer {
  box-sizing: border-box;
  padding-left: 280px;
}
.aui-page-sidebar.aui-sidebar-collapsed #footer {
  padding-left: 56px;
}
.aui-page-sidebar-touch #footer {
  margin-left: 0;
  padding-left: inherit;
  border-top: 1px solid #F4F5F7;
}
.aui-page-sidebar-touch.aui-sidebar-collapsed #footer {
  padding-left: inherit;
}
.aui-sidebar {
  box-sizing: border-box;
  min-width: 56px;
  position: absolute;
  width: 280px;
  z-index: 1;
}
#content .aui-sidebar + .aui-page-header {
  padding-left: 300px;
  background: #FFFFFF;
}
.aui-sidebar + .aui-page-header + .aui-navgroup {
  padding-left: 290px;
}
.aui-sidebar + .aui-page-panel {
  border-top: none;
}
.aui-sidebar ~ .aui-page-panel {
  margin-top: 0;
  padding-left: 280px;
}
.aui-page-sidebar-touch .aui-sidebar ~ .aui-page-panel {
  border-bottom: none;
  border-left: 1px solid #F4F5F7;
  clear: none;
  margin-left: 280px;
  padding-left: inherit;
  width: inherit;
}
.aui-page-sidebar-touch .aui-sidebar.aui-sidebar-fly-out {
  border-right: 1px solid #F4F5F7;
  height: 100%;
  position: absolute;
}
.aui-sidebar.aui-sidebar-fly-out ~ .aui-page-panel {
  padding-left: 56px;
  z-index: 0;
}
.aui-page-sidebar-touch .aui-sidebar.aui-sidebar-fly-out ~ .aui-page-panel {
  margin-left: 56px;
  padding-left: inherit;
}
#content .aui-sidebar.aui-sidebar-fly-out + .aui-page-header {
  padding-left: 76px;
}
.aui-sidebar .aui-sidebar-wrapper {
  box-sizing: border-box;
  border-right: 0;
  border-color: #F4F5F7;
  background-color: #F4F5F7;
  display: flex;
  flex-direction: column;
  width: 280px;
  position: absolute;
  top: 0;
  bottom: auto;
}
.aui-sidebar .aui-sidebar-wrapper.aui-is-docked {
  position: fixed;
  top: 0;
  bottom: auto;
}
.aui-page-sidebar-touch .aui-sidebar .aui-sidebar-wrapper {
  position: static;
}
.aui-page-sidebar-touch .aui-sidebar {
  background-color: #F4F5F7;
  float: left;
  position: static;
}
.aui-page-sidebar-touch .aui-sidebar .aui-sidebar-wrapper {
  border-right: none;
}
.aui-sidebar .aui-page-header {
  margin: 15px 15px 0 15px;
  padding-bottom: 0;
  position: relative;
}
.aui-sidebar .aui-page-header .aui-page-header-image {
  position: absolute;
}
.aui-sidebar .aui-page-header .aui-avatar {
  display: block;
}
.aui-sidebar .aui-page-header-inner,
.aui-sidebar .aui-page-header-image,
.aui-sidebar .aui-page-header-main {
  display: block;
}
.aui-sidebar .aui-page-header-image + .aui-page-header-main {
  padding-left: 58px;
}
.aui-sidebar .aui-sidebar-header-large .aui-page-header-image {
  width: 128px;
  margin: 0 auto;
  position: inherit;
}
.aui-sidebar .aui-sidebar-header-large .aui-page-header-image + .aui-page-header-main {
  padding-top: 10px;
  padding-left: 0;
}
.aui-sidebar .aui-sidebar-header-large .aui-page-header-main {
  text-align: center;
}
.aui-sidebar .aui-sidebar-header-large .aui-page-header-main > h1,
.aui-sidebar .aui-sidebar-header-large .aui-page-header-main > h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.01em;
  text-transform: none;
  padding-top: 10px;
}
.aui-sidebar .aui-sidebar-header-large .aui-page-header-main > h1:only-child,
.aui-sidebar .aui-sidebar-header-large .aui-page-header-main > h2:only-child {
  padding-top: 0;
}
.aui-sidebar .aui-page-header-main {
  padding-top: 4px;
}
.aui-sidebar .aui-page-header-main > h1,
.aui-sidebar .aui-page-header-main > h2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.006em;
  text-transform: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 20px;
}
.aui-sidebar .aui-page-header-main > h1:only-child,
.aui-sidebar .aui-page-header-main > h2:only-child {
  line-height: 48px;
}
.aui-sidebar .aui-page-header-actions {
  display: none;
}
.aui-sidebar .aui-nav-breadcrumbs > li {
  width: 100%;
}
.aui-sidebar .aui-sidebar-handle {
  background: #FFFFFF url(images/icons/sidebar/icon-handle.png) center center no-repeat;
  cursor: col-resize;
  height: 100%;
  position: absolute;
  right: -10px;
  top: 0;
  width: 10px;
}
.aui-sidebar .aui-sidebar-body {
  box-sizing: content-box;
  flex-grow: 1;
  overflow-y: auto;
}
.aui-sidebar .aui-sidebar-footer {
  box-sizing: border-box;
  border-top: 1px solid #F4F5F7;
  display: flex;
  flex-direction: row-reverse;
  flex-shrink: 0;
  justify-content: space-between;
  min-height: 40px;
  padding: 5px 10px;
}
.aui-page-sidebar-touch .aui-sidebar .aui-sidebar-footer {
  border-bottom: 1px solid #F4F5F7;
  margin-top: 10px;
}
.aui-sidebar .aui-sidebar-footer .aui-icon.aui-icon-small {
  vertical-align: text-bottom;
}
.aui-sidebar .aui-sidebar-footer .aui-button {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}
.aui-sidebar .aui-sidebar-footer .aui-button ~ .aui-button {
  margin-left: 0;
}
.aui-sidebar .aui-sidebar-footer > .aui-sidebar-toggle {
  order: -1;
}
.aui-sidebar .aui-sidebar-footer > .aui-sidebar-toggle > .aui-icon {
  background: url(images/icons/sidebar/icon-toggle.svg) center center no-repeat;
  background-size: 20px;
}
.aui-sidebar[aria-expanded="false"] {
  width: 56px;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-body {
  overflow-x: hidden;
}
#content .aui-sidebar[aria-expanded="false"] + .aui-page-header {
  padding-left: 76px;
}
.aui-sidebar[aria-expanded="false"] + .aui-page-header + .aui-navgroup {
  padding-left: 66px;
}
.aui-sidebar[aria-expanded="false"] ~ .aui-page-panel {
  padding-left: 56px;
}
.aui-page-sidebar-touch .aui-sidebar[aria-expanded="false"] ~ .aui-page-panel {
  border-left: 1px solid #DFE1E6;
  margin-left: 56px;
  padding-left: inherit;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-wrapper {
  width: 56px;
}
.aui-sidebar[aria-expanded="false"] .aui-page-header-inner .aui-page-header-image {
  align-content: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: auto;
}
.aui-sidebar[aria-expanded="false"] .aui-page-header {
  margin-left: 0;
  margin-right: 0;
}
.aui-sidebar[aria-expanded="false"] .aui-page-header .aui-avatar,
.aui-sidebar[aria-expanded="false"] .aui-page-header .aui-avatar .aui-avatar-inner {
  height: 32px;
  width: 32px;
}
.aui-sidebar[aria-expanded="false"] .aui-page-header .aui-avatar .aui-avatar-inner > img {
  max-height: 32px;
  max-width: 32px;
}
.aui-sidebar[aria-expanded="false"] .aui-page-header-main {
  display: none;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-body {
  box-sizing: border-box;
  bottom: 80px;
  padding: 0;
  width: 100%;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-footer {
  overflow: hidden;
  flex-direction: column-reverse;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-footer > .aui-sidebar-toggle > .aui-icon {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-footer > .aui-button {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  text-overflow: clip;
  white-space: normal;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-footer > .aui-button > .aui-button-label {
  display: none;
}
.aui-sidebar.aui-is-animated + .aui-page-panel {
  transition: padding-left 0.1s ease-in-out;
}
.aui-sidebar.aui-is-animated .aui-sidebar-wrapper {
  transition: width 0.1s ease-in-out;
}
.aui-sidebar.aui-is-animated .aui-sidebar-body {
  transition: padding-top 0.1s ease-in-out;
}
.aui-sidebar-section-tooltip {
  transition: opacity 0.1s ease-in-out;
  opacity: 0;
}
.aui-sidebar-section-tooltip .tipsy-inner {
  background: #333333;
}
.aui-sidebar-section-tooltip .tipsy-arrow {
  border-right-color: #333333;
}
.aui-sidebar-section-tooltip.tooltip-shown {
  opacity: 1;
}
.aui-sidebar .aui-navgroup .aui-sidebar-button,
.aui-sidebar-submenu .aui-navgroup .aui-sidebar-button {
  margin-bottom: 10px;
  margin-left: 3px;
}
.aui-sidebar .aui-navgroup .aui-sidebar-button > .aui-icon,
.aui-sidebar-submenu .aui-navgroup .aui-sidebar-button > .aui-icon {
  margin-right: 3px;
}
.aui-sidebar .aui-nav-item-label,
.aui-sidebar-submenu .aui-nav-item-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  word-wrap: normal;
}
.aui-sidebar .aui-navgroup-vertical .aui-nav > li > .aui-nav-item,
.aui-sidebar-submenu .aui-navgroup-vertical .aui-nav > li > .aui-nav-item {
  border-radius: 3px;
  box-sizing: content-box;
  display: block;
  line-height: 1.42857143;
  padding: 7px 10px;
  padding-left: 7px;
  padding-right: 23px;
}
.aui-sidebar .aui-navgroup-vertical .aui-nav .aui-nav-item-actions,
.aui-sidebar-submenu .aui-navgroup-vertical .aui-nav .aui-nav-item-actions {
  top: 9px;
  height: 16px;
  width: 16px;
}
.aui-sidebar .aui-navgroup-vertical .aui-nav .aui-nav[data-more] .aui-nav-more ~ li,
.aui-sidebar-submenu .aui-navgroup-vertical .aui-nav .aui-nav[data-more] .aui-nav-more ~ li {
  height: 0;
  overflow: hidden;
}
.aui-sidebar .aui-navgroup-vertical .aui-nav .aui-nav .aui-nav-item-actions,
.aui-sidebar-submenu .aui-navgroup-vertical .aui-nav .aui-nav .aui-nav-item-actions {
  margin-right: 0;
}
.aui-sidebar .aui-navgroup-vertical .aui-nav .aui-nav-selected > .aui-nav-item,
.aui-sidebar-submenu .aui-navgroup-vertical .aui-nav .aui-nav-selected > .aui-nav-item {
  background-color: rgba(9, 30, 66, 0.08);
  color: #42526E;
  font-weight: 500;
}
.aui-sidebar .aui-nav > li,
.aui-sidebar-submenu .aui-nav > li {
  position: relative;
}
.aui-sidebar .aui-nav > li > .aui-nav-item,
.aui-sidebar-submenu .aui-nav > li > .aui-nav-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.aui-sidebar .aui-nav > li > .aui-nav-item > .aui-icon,
.aui-sidebar-submenu .aui-nav > li > .aui-nav-item > .aui-icon {
  color: #505F79;
  height: 20px;
  width: 20px;
  margin-right: 10px;
  float: left;
}
.aui-sidebar .aui-nav > li > .aui-nav-item > .aui-icon::before,
.aui-sidebar-submenu .aui-nav > li > .aui-nav-item > .aui-icon::before {
  font-size: 20px;
  margin-top: -10px;
}
.aui-sidebar .aui-nav > li > .aui-nav-item > .aui-icon.aui-icon-small::before,
.aui-sidebar-submenu .aui-nav > li > .aui-nav-item > .aui-icon.aui-icon-small::before {
  font-size: 16px;
  padding: 2px;
}
.aui-sidebar .aui-navgroup-vertical .aui-nav > li[aria-expanded] > .aui-nav-subtree-toggle,
.aui-sidebar-submenu .aui-navgroup-vertical .aui-nav > li[aria-expanded] > .aui-nav-subtree-toggle {
  top: 7px;
  left: 7px;
  color: #505F79;
}
.aui-sidebar .aui-navgroup-vertical .aui-nav > li[aria-expanded] > .aui-nav-subtree-toggle + .aui-nav-item,
.aui-sidebar-submenu .aui-navgroup-vertical .aui-nav > li[aria-expanded] > .aui-nav-subtree-toggle + .aui-nav-item {
  padding-left: 37px;
}
.aui-sidebar .aui-navgroup-vertical .aui-nav > li[aria-expanded] > .aui-nav-subtree-toggle + .aui-nav-item > .aui-icon,
.aui-sidebar-submenu .aui-navgroup-vertical .aui-nav > li[aria-expanded] > .aui-nav-subtree-toggle + .aui-nav-item > .aui-icon {
  display: none;
}
.aui-sidebar .aui-navgroup-vertical .aui-nav > li[aria-expanded] > .aui-nav-subtree-toggle ~ .aui-nav,
.aui-sidebar-submenu .aui-navgroup-vertical .aui-nav > li[aria-expanded] > .aui-nav-subtree-toggle ~ .aui-nav {
  padding-left: 30px;
}
.aui-sidebar hr,
.aui-sidebar-submenu hr {
  display: none;
}
.aui-sidebar[aria-expanded="false"] .aui-nav-heading {
  display: none;
}
.aui-sidebar[aria-expanded="false"] .aui-navgroup-vertical .aui-nav > li[aria-expanded] > .aui-nav-subtree-toggle + .aui-nav-item {
  padding-left: 0;
}
.aui-sidebar[aria-expanded="false"] .aui-navgroup-vertical .aui-nav > li[aria-expanded] > .aui-nav-subtree-toggle + .aui-nav-item > .aui-icon {
  display: block;
}
.aui-sidebar[aria-expanded="false"] .aui-nav-sortable > li > a:after {
  left: 0;
  top: 8px;
}
.aui-sidebar[aria-expanded="false"] .aui-nav-item-actions {
  display: none;
}
.aui-sidebar-submenu-dialog > .aui-inline-dialog-contents {
  padding: 10px;
  width: 300px;
  max-height: 812px;
}
.aui-sidebar-submenu {
  position: static;
  background: transparent;
  border-right: 0;
  width: auto;
}
.aui-sidebar .aui-sidebar-group {
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 0 10px;
}
.aui-sidebar .aui-sidebar-group .aui-nav-heading {
  padding: 0 5px;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group {
  border-radius: 3px;
  box-sizing: content-box;
  display: block;
  line-height: 1.42857143;
  padding: 7px 10px;
  background-color: transparent;
  color: #42526E;
  text-decoration: none;
  background: url(images/icons/sidebar/icon-group.svg) 50% center no-repeat;
  cursor: pointer;
  height: 20px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group:focus,
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group:hover {
  background-color: rgba(9, 30, 66, 0.08);
  text-decoration: none;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group.active {
  background-color: rgba(9, 30, 66, 0.08);
  color: #42526E;
  font-weight: 500;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group > .aui-nav {
  display: none;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group.aui-sidebar-group-actions,
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group.aui-sidebar-group-tier-one {
  background: none;
  border-radius: 0;
  cursor: auto;
  padding: 0;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-actions {
  margin: 10px 0 15px 0;
  height: 32px;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-actions::after {
  background: url(images/icons/sidebar/icon-group-actions.svg) no-repeat center center;
  background-size: 20px;
  border: 1px solid #F4F5F7;
  border-radius: 50%;
  content: "";
  display: block;
  height: 32px;
  left: 0;
  margin-left: 11px;
  margin-right: 11px;
  position: absolute;
  top: 0;
  width: 32px;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-actions:hover::after,
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-actions:focus::after,
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-actions.active::after {
  background-color: #FFFFFF;
  border-color: #DFE1E6;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-actions ~ .aui-sidebar-group-tier-one {
  border-top: 2px solid #DFE1E6;
  margin-top: 15px;
  padding-top: 10px;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one {
  background: none;
  height: auto;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one:after {
  display: none;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one:focus,
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one:hover {
  background-color: transparent;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one .aui-nav {
  display: block;
  height: auto;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one .aui-nav > li {
  display: block;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one .aui-nav > li > .aui-nav-item {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one .aui-nav > li > .aui-nav-item > .aui-icon {
  float: none;
  margin: 0 auto;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one .aui-nav > li > .aui-nav-item.active {
  background-color: rgba(9, 30, 66, 0.08);
  color: #42526E;
  font-weight: 500;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one .aui-nav > li .aui-nav-item-label {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one .aui-nav > li > .aui-nav-subtree-toggle {
  display: none;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one .aui-nav > li .aui-nav-item-actions {
  display: none;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one .aui-nav .aui-nav {
  display: none;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one ~ .aui-sidebar-group-tier-one {
  border-top: 2px solid #DFE1E6;
  margin-top: 10px;
  padding-top: 10px;
}
.aui-sidebar .aui-nav > li > .aui-nav-item > .aui-badge,
.aui-sidebar-submenu .aui-nav > li > .aui-nav-item > .aui-badge,
.aui-sidebar .aui-nav > li > .aui-nav-item > aui-badge,
.aui-sidebar-submenu .aui-nav > li > .aui-nav-item > aui-badge {
  background-color: #C1C7D0;
  border-color: #F4F5F7;
  color: rgba(9, 30, 66, 0.95);
  float: right;
  margin-top: 2px;
}
.aui-sidebar[aria-expanded="false"] .aui-nav > li > a:hover > .aui-nav-item > .aui-badge {
  visibility: visible;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one .aui-nav > li > .aui-nav-item > .aui-badge,
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one .aui-nav > li > .aui-nav-item > aui-badge {
  border-width: 2px;
  border-style: solid;
  font-size: 9px;
  margin-top: 0;
  padding: 0.2em 0.4em;
  position: absolute;
  right: -0.5em;
  top: -0.5em;
  z-index: 1;
}
.aui-checkbox-multiselect {
  display: inline-block;
}
.aui-checkbox-multiselect .aui-button {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.aui-checkbox-multiselect-dropdown .hidden {
  display: none;
}
.aui-checkbox-multiselect-dropdown .aui-button-link {
  margin-left: 10px;
}
/**
 * Toggle button
 */
aui-toggle {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  height: 2.16666667em;
  padding: 6px 8px;
  position: relative;
  vertical-align: baseline;
  width: 58px;
}
aui-toggle::before {
  content: "\00a0";
}
aui-toggle::after {
  background-color: transparent;
  border-radius: 3em;
  bottom: 0;
  content: '';
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
}
aui-toggle:not([disabled]):not([busy]):hover .aui-toggle-input + .aui-toggle-view {
  background-color: #A5ADBA;
}
aui-toggle:not([disabled]):not([busy]):hover .aui-toggle-input:checked + .aui-toggle-view {
  background-color: #36B37E;
}
aui-toggle[disabled],
aui-toggle[busy] {
  cursor: auto;
}
aui-toggle[disabled] .aui-toggle-input,
aui-toggle[busy] .aui-toggle-input {
  cursor: auto;
  pointer-events: none;
}
aui-toggle[busy] .aui-toggle-tick::before,
aui-toggle[busy] .aui-toggle-cross::before {
  opacity: 0;
  transition: auto;
}
aui-toggle[busy]::after {
  background-color: rgba(255, 255, 255, 0.5);
}
aui-toggle[busy] aui-spinner[size="small"] {
  color: inherit;
  position: relative;
  top: -2px;
}
aui-toggle[disabled] .aui-toggle-view {
  background-color: #F4F5F7;
}
aui-toggle[disabled] .aui-toggle-tick,
aui-toggle[disabled] .aui-toggle-cross {
  color: #A5ADBA;
}
.aui-toggle-input {
  -webkit-appearance: button;
  -moz-appearance: radio-container;
  border-radius: 3em;
  bottom: 0;
  cursor: pointer;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.aui-toggle-view {
  background-color: #6B778C;
  border-radius: 3em;
  bottom: 0;
  box-sizing: border-box;
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  transition: background-color 100ms linear;
  top: 0;
}
.aui-toggle-view::after {
  background-color: #FFFFFF;
  border-radius: 3em;
  bottom: 1px;
  content: '';
  display: block;
  left: 1px;
  position: absolute;
  top: 1px;
  -webkit-transition: -webkit-transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out;
  width: 24px;
}
.aui-toggle-tick,
.aui-toggle-cross {
  position: absolute;
  top: 5px;
}
.aui-toggle-tick::before,
.aui-toggle-cross::before {
  left: 50%;
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  transition: opacity 250ms ease-in-out;
}
.aui-toggle-tick {
  color: #FFFFFF;
  left: 8px;
}
.aui-toggle-cross {
  color: #FFFFFF;
  right: 8px;
}
.aui-toggle-input:checked:enabled + .aui-toggle-view,
.aui-toggle-input.indeterminate-checked + .aui-toggle-view {
  background-color: #00875A;
}
.aui-toggle-input:checked + .aui-toggle-view::after,
.aui-toggle-input.indeterminate-checked + .aui-toggle-view::after {
  -ms-transform: translateX(32px);
  -webkit-transform: translateX(32px);
  transform: translateX(32px);
}
