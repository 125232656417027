@import './imports/global';

/*! AUI Label */
.aui-label {
    background: @aui-label-bg-color;
    border: 1px solid @aui-label-border-color;
    border-radius: @aui-label-border-radius;
    color: @aui-label-link-color;
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    padding: 1px 5px;
    margin: 0 5px 0 0;
    text-align: left;
    text-decoration: none;
}

a.aui-label {
  color: @aui-label-link-color;
}

span.aui-label {
    color: @aui-label-text-color;
}

/* Need the split hover to trigger border on parent while split label/X are hovered */
.aui-label.aui-label-closeable.aui-label-split:hover,
a.aui-label:focus,
a.aui-label:hover,
a.aui-label:active {
    border-color: @aui-label-hover-border-color;
    text-decoration: none;
}

.aui-label-split .aui-label-split-main:hover,
.aui-label-split .aui-label-split-main:active,
.aui-label-split .aui-label-split-main:focus{
    text-decoration: none;
}

.aui-label.aui-label-closeable {
    padding-right: 18px;
    position: relative;
}

.aui-label-closeable .aui-icon-close {
    /* Small X icon */
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAQAAABuBnYAAAAAKUlEQVQI12NgYFj0nwEKwKxF/9P+Q4TgLAgDIQEVQuJiCKBpwWoosrUAzbg31XT4p6QAAAAASUVORK5CYII=') 0 0 no-repeat;
    cursor: pointer;
    display: block;
    float: right;
    height: 8px;
    position: absolute;
    right: 5px;
    top: 4px;
    width: 8px;
}
