/**
 * AUI Module
 */
.aui-module {
    margin-top: 20px;
}
.aui-module:first-child {
    margin-top: 0;
}
.aui-module-header,
.aui-module-content,
.aui-module-footer {
    box-sizing: border-box;
}
